import { useState } from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const Headline = styled.h1`
  font-size: 2rem;
  margin: 5px 0;

  @media only screen and (min-width: 768px) {
    margin: 20px 0;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: center;
  color: palevioletred;
  padding: 0 10px;
`;

const StyledButton = styled.button`
  padding: 10px 20px;
`;

const Imprint = styled.div`
  font-size: 0.6rem;
  color: #999;
`;

const SmallPrinted = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: 10px;
`;

const IngerientContainer = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fde68a;

  > * + * {
    margin-top: 10px;
  }

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    margin-bottom: 0;
    
    > * + * {
      margin-top: 0;
      margin-left: 10px;
    }
  }
`;

const AmountContainer = styled.div`
  display: flex;
  flex-direction: row;

  > * {
    flex: 1;
  }

  > * + * {
    margin-left: 10px;
  }
`;

const ButtonBar = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;

  > * + * {
    margin-left: 10px;
  }
`;

const IngredientLabel = styled.h2`
  margin: 5px 10px;
  font-size: 1rem;

  @media only screen and (min-width: 768px) {
    margin-top: 20px;
  }
`;

const StyledInput = styled.input`
  border: 5px solid white;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1), 0 0 16px rgba(0, 0, 0, 0.1);
  padding: 5px;
  background-color: #FFFBEB;
  margin: 0;
  width: 100%;
  @media only screen and (min-width: 768px) {
    padding: 15px;
  }
  &:disabled {
    color: #000;
    background-color: #FEF3C7;
  }
  ${(props) =>
    props.numeric &&
    css`
      text-align: right;
    `}
`;

const InputContainer = styled.div``;

const InputLabel = styled.label``;

function Input(props) {
  const { label, ...rest } = props;
  return (
    <InputContainer>
      <InputLabel>{label}</InputLabel>
      <StyledInput {...rest} />
    </InputContainer>
  );
}

function App() {
  const [refAmount, setRefAmount] = useLocalStorage("refAmount", 1);
  const [amount, setAmount] = useLocalStorage("amount", 1);
  const [baseIngredient, setBaseIngredient] = useLocalStorage(
    "baseIngredient",
    ""
  );
  const [list, setList] = useLocalStorage("list", [
    { name: "", amount: "" },
    { name: "", amount: "" },
    { name: "", amount: "" },
  ]);

  const factor = amount / refAmount;

  const updateAmount = (idx, amount) => {
    setList((l) => {
      l[idx].amount = amount;
      return [...l];
    });
  };

  const updateName = (idx, name) => {
    setList((l) => {
      l[idx].name = name;
      return [...l];
    });
  };

  const addRow = () => {
    setList((l) => {
      return [...l, { name: "", amount: "" }];
    });
  };

  const deleteAll = () => {
    setList([
      { name: "", amount: "" },
      { name: "", amount: "" },
      { name: "", amount: "" },
    ]);
    setRefAmount(1);
    setAmount(1);
    setBaseIngredient("");
  };

  return (
    <Container>
      <Header>
        <Headline>Matheas Zutatenumrechner</Headline>
      </Header>
      <IngredientLabel>Basis Zutat</IngredientLabel>
      <IngerientContainer>
        <Input
          label="Name"
          autoFocus
          value={baseIngredient}
          onChange={(e) => setBaseIngredient(e.target.value)}
        />
        <AmountContainer>
          <Input
            label="Normale Menge"
            numeric
            value={refAmount}
            onChange={(e) => setRefAmount(e.target.value)}
          />
          <Input
            label="Neue Menge"
            numeric
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </AmountContainer>
      </IngerientContainer>

      <IngredientLabel>Weitere Zutaten</IngredientLabel>

      {list.map((item, idx) => (
        <IngerientContainer key={idx}>
          <Input
            label="Name"
            value={item.name}
            onChange={(e) => updateName(idx, e.target.value)}
          />
          <AmountContainer>
            <Input
              label="Normale Menge"
              numeric
              value={item.amount}
              onChange={(e) => updateAmount(idx, e.target.value)}
            />
            <Input
              label="Neue Menge"
              disabled
              numeric
              value={calcAndFormat(item.amount, factor)}
            />
          </AmountContainer>
        </IngerientContainer>
      ))}

      <ButtonBar>
        <StyledButton onClick={addRow}>Weitere Zutat</StyledButton>
        <StyledButton onClick={deleteAll}>Alles löschen</StyledButton>
      </ButtonBar>

      <SmallPrinted>
        <Imprint>
          Datenschutzerklärung: Diese Seite sammelt keine persönliche Daten und
          verwendet keine Cookies.
        </Imprint>

        <Imprint>
          Impressum: Für Inhalt verantwortlich: Florian Fankhauser, A-6060 Hall
        </Imprint>

        <Imprint>Version: {process.env.REACT_APP_VERSION}</Imprint>
      </SmallPrinted>
    </Container>
  );
}

// Hook
function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

function parseNumber(s) {
  if (s === undefined) {
    return NaN;
  }

  s = s.replace(",", ".");
  return parseFloat(s);
}

function calcAndFormat(amount, factor) {
  const amountVal = parseNumber(amount);
  if (isNaN(amountVal) || isNaN(factor)) {
    return "---";
  }

  const v = amountVal * factor;
  const vStr = v.toFixed(2).toString();
  return vStr.replace(".", ",");
}

export default App;
